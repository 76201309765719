<template>
  <div class="g_page_box">
    <div class="g_main_content main_content">
      <g-loading :loading="loading" />
      <img src="../../../assets/img/registered/fail.png" class="icon" width="153">
      <div class="title">认证失败</div>
      <div class="surplus_time">失败原因：{{err_msg}}</div>
    </div>
    <div class="bottom_box1">
      <div class="btn" @click="change_name">在做一次</div>
    </div>
    <div class="bottom_box">
      <div class="btn" @click="to_home">返回首页</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      err_msg: decodeURIComponent(this.$route.query.err_msg),
    }
  },
  computed: {
  },
  components: {
  },
  created () {
  },
  methods: {
    to_home() {
      this.$router.replace({ path: '/home' })
    },
    change_name() {
      this.$router.replace({ path: '/health-card' })

    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_content {
  text-align: center;
}
.icon {
  margin-top: 65px;
}
.title {
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  color: #242938;
  margin-top: 28px;
}
.bottom_box {
  padding: 0px 13px 24px;
  & .btn {
    width: 100%;
    background: #0088FF;
    border-radius: 9px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
  }
}
.surplus_time {
  font-size: 14px;
  line-height: 20px;
  color: #242938;
  margin-top: 8px;
  & label {
    color: #0088FF;
  }
}
.bottom_box1 {
  padding: 9px 13px;
  & .btn {
    width: 100%;
   height: 40px;
    background: #FFFFFF;
    border: 1px solid #0088FF;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    color: #0088FF;
    border-radius: 9px;
  }
}
</style>
